import React, { useState } from "react";
import { Table, Button, Tag, Tooltip, message, Modal, Tabs, Alert } from "antd";
import { calculateOtherWarehouseStocks, findStock, getFastestAvability, getOtherSeller } from "@/core/avability/avability2";
import BuyAreav2 from "../products/BuyAreav2/BuyAreav2";
import useCurrencyWithCode from "@/hooks/useCurrencyWithCode";
import BuyAreav2Logistic from "../products/BuyAreav2/BuyAreav2Logistic";
import useIsAddedBasketByRowId from "@/hooks/useIsAddedBasketByRowId";
import { useDispatch, useSelector } from "react-redux";
import BasketIcon from "../icons/BasketIcon";
import PhoneIcon from "../icons/PhoneIcon";
import { LoadingOutlined } from "@ant-design/icons";
import CounterButton from "../products/BuyAreav2/CounterButton";
import { deleteBasketById, deleteBasketByProductReferenceAndSupplierId, insertNewBasket, updateStoreBasket } from '@/store/basket';
import { useTranslation } from "react-i18next";
import ApplicationList from "../products/ApplicationList";
import ApplicationListTecdoc from "../products/ApplicationListTecdoc";

import { Image } from 'antd';
import getCatalogName from "@/helpers/getCatalogName";

const OemList = ({ oemData }) => {
    // Hata kontrolü için durumu belirleme
    const hasError = !Array.isArray(oemData) || oemData.length === 0;

    const columns = [
        {
            title: 'Article Number',
            dataIndex: 'articleNumber',
            key: 'articleNumber',
        },
        {
            title: 'Manufacturer ID',
            dataIndex: 'mfrId',
            key: 'mfrId',
        },
        {
            title: 'Manufacturer Name',
            dataIndex: 'mfrName',
            key: 'mfrName',
        },
    ];

    if (hasError) return (<Alert
        message="No OEM data available or the data format is incorrect."
        type="error"
    />)

    return (<Table
        dataSource={oemData}
        columns={columns}
        pagination={false}
        rowKey="articleNumber"
    />)

};

const TecdocTabs = ({ record }) => {

    const { t } = useTranslation()

    const tabList = [
        {
            label: t('compatibility'),
            key: 1,
            children: <ApplicationListTecdoc onSelectVehicle={() => { }} reference={record?.TecdocReference} brandId={record?.TecdocBrandId} />,
        }
        // }, {
        //     label: t('oem'),
        //     key: 2,
        //     children: <OemList oemData={record?.OEM} />
        // }
    ];



    return (<Tabs
        tabPosition={'left'}
        items={tabList}
    />)
}

const TecdocMenu = ({ record }) => {


    const [visibleExtra, setVisibleExtra] = useState(false);

    if (!record.TecdocArticleId) return null;

    return (
        <div>

            <style>
                {`
                    .green-dot {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  animation: blink 1s infinite;
}

/* Animasyon tanımlaması */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
}

                `}
            </style>

            <div className="green-dot"></div>


            <Tooltip title="Daha fazla özellik için tıklayın">
                <img
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '50px',
                        height: '50px',
                        objectFit: 'contain',
                        cursor: 'pointer'
                    }}
                    src="https://material.parts-soft.net/webshop-media/tecdocIcon.png"
                    onClick={() => {
                        setVisibleExtra(true)
                    }}
                />
            </Tooltip>


            <Modal width={'100%'} title={'Tecdoc'} footer={null} open={visibleExtra} onCancel={() => setVisibleExtra(false)}>
                <TecdocTabs record={record} />
            </Modal>

        </div>
    )
}



const BasketArea = ({ record, deliveries, settings }) => {

    const d = useDispatch();

    const [loadingBasket, setLoadingBasket] = useState(false)

    const fastestAvability = getFastestAvability(record?.avability, deliveries)

    const rowId = fastestAvability?.rowId


    const isAddedBasketByRowId = useIsAddedBasketByRowId(rowId)


    const stocks = fastestAvability?.stocks;

    const minimumOrderQuantity = fastestAvability?.TotalPiecesInPackage || 1

    const qty = isAddedBasketByRowId?.Quantity  //baskete ekli olan qty


    const activeStock = findStock(stocks, (qty || 0)); //şuanki depodan geliş zamanı etc.

    const price = fastestAvability?.price

    const saleStatus = activeStock && price?.NetPrice;

    const onChangeQty = (type, value) => {
        if (type === 'decrease') {
            updateBasketHandler(parseInt(qty) - parseInt(minimumOrderQuantity));
        } else if (type === 'increment') {
            updateBasketHandler(parseInt(qty) + parseInt(minimumOrderQuantity));
        } else if (type === 'change') {
            if (parseInt(value) % minimumOrderQuantity !== 0) {
                return message.error('Invalid value');
            }
            updateBasketHandler(parseInt(value));
        }
    };

    const updateBasketHandler = (quantity) => {
        if (quantity < minimumOrderQuantity) {
            setLoadingBasket(true);
            d(deleteBasketById({
                basketId: isAddedBasketByRowId?.Id,
                cb: () => {
                    setLoadingBasket(false);
                }
            }));
        } else {
            setLoadingBasket(true);
            console.log({
                basketId: isAddedBasketByRowId?.Id,
                Quantity: quantity,
                cb: () => {
                    setLoadingBasket(false);
                }
            });
            d(updateStoreBasket({
                basketId: isAddedBasketByRowId?.Id,
                Quantity: quantity,
                cb: () => {
                    setLoadingBasket(false);
                }
            }));
        }
    };

    const addToBasket = () => {
        setLoadingBasket(true)
        try {


            return d(insertNewBasket({
                quantity: minimumOrderQuantity,
                RowId: rowId,
                cb: (err) => {
                    console.log(err)
                    setLoadingBasket(false)
                }
            }))

        } catch (e) {
            console.log(e)
        }
        setLoadingBasket(false)
    }


    return (<>
        {
            isAddedBasketByRowId ? <CounterButton value={parseInt(qty)} onChange={onChangeQty} /> : <button onClick={addToBasket} disabled={!saleStatus} className=" flex items-center justify-around p-[5px] relative rounded-[6px] w-[100%]" style={{ backgroundColor: settings?.['HeaderBgColor'] }}>
                {saleStatus ? (loadingBasket ? <LoadingOutlined style={{
                    color: 'white',
                    fontSize: '24px'
                }} /> : <BasketIcon className="relative w-[24px] h-[24px]" />) : <PhoneIcon color={settings['HeaderTextColor']} className="relative w-[24px] h-[24px]" />}
            </button>
        }
    </>)




}

const LogisticArea = ({ record, deliveries, settings }) => {

    const fastestAvability = getFastestAvability(record?.avability, deliveries)

    const stocks = fastestAvability?.stocks;

    const price = fastestAvability?.price

    const isExist = fastestAvability?.isExist //Direct mi?

    const rowId = fastestAvability?.rowId

    const otherWarehouseStocks = calculateOtherWarehouseStocks(stocks) // diğer satıcılar

    const isAddedBasketByRowId = useIsAddedBasketByRowId(rowId)

    const qty = isAddedBasketByRowId?.Quantity  //baskete ekli olan qty

    const activeStock = findStock(stocks, (qty || 0)); //şuanki depodan geliş zamanı etc.

    const saleStatus = activeStock && price?.NetPrice;
    const minimumOrderQuantity = fastestAvability?.TotalPiecesInPackage || 1

    const isAdmin = (useSelector((e) => e?.session?.account?.Role) == 1) ? true : false
    const timestamp = activeStock?.logisticTime?.timestamp
    const timestampToRegion = activeStock?.logisticTime?.timestampToRegion

    return (<BuyAreav2Logistic supplierId={fastestAvability?.supplierId} logisticTime={timestamp} logisticTimeToRegion={timestampToRegion} saleStatus={saleStatus} otherWarehouseStocks={otherWarehouseStocks} StockQuantity={parseInt(activeStock?.StockQuantity)} isExist={isExist} />)

}



const addToBasket = (record) => {
    console.log("Sepete ekle: ", record);
};

const ImageCell = ({ src, alt, width = 50, height = 50 }) => {
    const [isError, setIsError] = useState(false);

    if (!src || isError) {
        return null; // Resim yoksa veya yüklenemezse hiçbir şey render etme
    }

    return (
        <Image
            src={src}
            alt={alt || "product"}
            width={width}
            height={height}
            style={{ objectFit: "contain" }}
            preview={{ mask: 'Resmi Görüntüle' }} // Resme tıklanabilirlik kazandırır
            onError={() => setIsError(true)} // Hata olursa resmi gizle
        />
    );
};



const ProductTable = ({ dataSource, deliveries, pageSize }) => {

    const formatedPrice = useCurrencyWithCode()

    const settings = useSelector(e => e.settings);

    const language = useSelector((e) => e.settings.Language)

    const { t } = useTranslation()

    const catalogName = getCatalogName()

    const columns = [
        ...(catalogName === 'TECDOC' ? [{
            title: "Tecdoc",
            dataIndex: "Tecdoc",
            key: "tecdoc",
            render: (_, record) => <TecdocMenu record={record} />,
            width: 90
        }] : []),
        {
            title: t('reference'),
            dataIndex: "ProductReference1",
            key: "productReference",
            width: 200,
        },
        {
            title: t('description'),
            key: "Description",
            ellipsis: { showTitle: false },
            render: (_, record) => {
                let _description = '-';  // Varsayılan değeri '-' olarak belirle
                try {
                    const desc = record?.Description || {};
                    if (desc[language?.toUpperCase()]) {
                        _description = desc[language.toUpperCase()];
                    } else if (Object.keys(desc).length > 0) {
                        _description = desc[Object.keys(desc)[0]];
                    }
                } catch (e) {
                    console.error('Description hatası:', e);
                }

                return (
                    <Tooltip placement="topLeft" title={_description}>
                        {_description}
                    </Tooltip>
                );
            },
        },
        {
            title: t('brand'),
            dataIndex: "BrandName",
            key: "brand",
            width: 120,
        },
        {
            title: t('image'),
            dataIndex: "Photo",
            key: "photo",
            render: (photo) => photo ? <ImageCell src={photo} /> : <div></div>,
            width: 80,
        },
        {
            title: t('price'),
            key: "netPrice",
            width: 100,
            render: (_, record) => {
                const fastestAvability = getFastestAvability(record?.avability, deliveries);
                return formatedPrice(fastestAvability?.price?.NetPrice || 0);  // Varsayılan fiyat 0
            }
        },
        {
            title: t('stock'),
            key: "stock",
            width: 100,
            render: (_, record) => {
                const fastestAvability = getFastestAvability(record?.avability, deliveries);
                return fastestAvability?.fastestStockQuantity || '-';  // Boşsa '-'
            },
        },
        {
            title: t('logistics'),
            key: "logistics",
            width: 270,
            render: (_, record) => (
                <LogisticArea settings={settings} record={record} deliveries={deliveries} />
            ),
        },
        {
            title: t('basket'),
            key: "basket",
            width: 170,
            render: (_, record) => (
                <BasketArea settings={settings} record={record} deliveries={deliveries} basket={record} />
            ),
        },
    ].filter(Boolean);  // undefined veya false değerleri filtrele




    return (
        <div>
            <style>
                {`.table-row-light {
  background-color: #ffffff; /* Beyaz */
}

.table-row-dark {
  background-color: #f9f9f9; /* Açık gri */
}
`}
            </style>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowKey="Id"
                pagination={{ pageSize: pageSize }}
                scroll={{ x: 1000 }}
                rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
            // components={{
            //     body: {
            //         row : CustomRow
            //     }
            // }}
            />
        </div>


    );
};

export default ProductTable;
