import { useEffect, useState } from "react";
import { Divider, List, Spin, Modal } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import searchVehicle from "@/core/vehicle/searchVehicleFromPlate";
import useAddTab from '@/hooks/assistant/useAddTab';
import groupArray from "group-array";
import SearchValidation from "./SearchValidation";
import getCatalogName from "@/helpers/getCatalogName";

const ResultVehicle = ({ plate, description }) => {
  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);
  const [_data, _setData] = useState([])
  const [anothers, setAnothers] = useState([])
  const d = useDispatch();
  const addTab = useAddTab()
  const { t } = useTranslation();
  const [showSetMotorCode, setShowMotorCode] = useState(false);
  const [motorCodeList, setMotorCodeList] = useState([])
  const catalogName = getCatalogName()
  const addTabAction = (item) => {
    addTab({
      vehicleLineId: item.VehicleLineId,
      vehicleLineLineName: item.VehicleLineName,
      section: "vehicleMenu",
      make: item.VehicleMakeId,
      plate: plate,
      makeName: item.VehicleMakeName,
      type: "assistant",
      vehicleSeriesName: item.VehicleReferenceName,
      vehicleSeriesId: item.VehicleReferenceId,
      title: item.VehicleMakeName + " " + item.VehicleLineName + " " + item.VehicleReferenceName + " / " + plate.toUpperCase()
    }, 'redirect')

  }


  const onClickVehicleHandler = (item) => {
    if (item.isGrouped) {
      setMotorCodeList({ others: item.others, line: item.VehicleLineId })
      setShowMotorCode(true);
    }
    else
      addTabAction(item)
  };


  const selectedMotorCodeHandler = motorCode => {
    setShowMotorCode(false);
    let find = (_data.find(e => e.VehicleLineId === motorCode.line && e.EngineType == motorCode.motorCode))
    addTabAction(find)
  }

  useEffect(() => {
    setLoading(true)
    _setData([])
    searchVehicle(plate).then(e => {
      //Gruplanmış plakalarda motor kodları farklı o yüzden bir den fazla döndürüyor.
      let __data = e;
      console.log(e)
      let grouped = groupArray(e, 'VehicleReferenceName')
      let list = []
      Object.keys(grouped).forEach(key => {
        if (grouped[key].length > 1) {
          let othersList = grouped[key].map(item => item.EngineType)
          for (let i = 0; i < grouped[key].length; i++) {
            grouped[key][i].isGrouped = true;
            grouped[key][i].others = othersList
          }
        }
        list.push(grouped[key][0])
      })
      setData(list)
      _setData(e)
    }).catch(f => {
      console.log(f)
    }).finally(() => setLoading(false))
  }, [plate]);

  return (
    <Spin tip="Loading" spinning={loading}>
      <p style={{ color: "#A5A5A5", fontSize: "20px" }}>Auto's</p>

      <Modal title={t('motor-code')} footer={null} zIndex={999999} open={showSetMotorCode} onCancel={setShowMotorCode} >
        <SearchValidation onClickMotorCode={selectedMotorCodeHandler} motorCodes={motorCodeList} />
      </Modal>


      <List>
        {Array.isArray(data) && data.map((item, key) => (
          <List.Item className="cursor-pointer" onClick={() => onClickVehicleHandler(item)} key={key}>
            <div className="search-card-item">
              <div className="search-card-item__img">
                <img

                  src={
                    catalogName === 'TECDOC'
                      ? 'https://material.parts-soft.net/webshop-media/tecdoc/' + item?.VehicleReferenceId + '.jpg'
                      : item.Image
                  }
                  onError={(e) => {
                    e.target.src = ''; // Varsayılan bir resim
                  }} />
              </div>
              <div className="search-card-item__description only-show-desktop">
                <div className="search-card-item__description__title">{`${item.VehicleMakeName} ${item.VehicleLineName} ${item.VehicleReferenceName} ${item.BodyType}`}</div>
                <div className="search-card-item__description__sub">{`${item.isGrouped ? '' : item.EngineType} ${item.VehicleLineName} ${item.VehicleReferenceName} ${item.BodyType}`}</div>
              </div>
              <div className="search-card-item__plate">
                <div className="search-card-item__plate__1">{"NL"}</div>
                <div className="search-card-item__plate__2">{plate.toUpperCase()}</div>
              </div>
            </div>
            <div className="search-card-item__description mt-5 text-center only-show-mobile w-full">
              <div className="search-card-item__description__title text-center">{`${item.VehicleMakeName} ${item.VehicleLineName} ${item.VehicleReferenceName} ${item.BodyType}`}</div>
              {!anothers.length ? <div className="search-card-item__description__sub">{`${item.isGrouped ? '' : item.EngineType} ${item.VehicleLineName} ${item.VehicleReferenceName} ${item.BodyType}`}</div> : null}
            </div>
          </List.Item>
        ))}
      </List>
      <Divider />
    </Spin>
  );
};

export default ResultVehicle;
