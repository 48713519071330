import React, { useState } from 'react'


import GarageIcon from "../../icons/GarageIcon";
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import { getFastestAvability, calculateOtherWarehouseStocks, findStock, timestampToFullDate, formatDateTime } from '@/core/avability/avability2';
import { Badge, message } from 'antd';
import useIsAddedBasketByRowId from '@/hooks/useIsAddedBasketByRowId';
import CounterButton from './CounterButton';
import { deleteBasketById, deleteBasketByProductReferenceAndSupplierId, insertNewBasket, updateStoreBasket } from '@/store/basket';
import useCurrencyWithCode from '@/hooks/useCurrencyWithCode';
import PhoneIcon from '@/components/icons/PhoneIcon';
import BuyAreav2Logistic from './BuyAreav2Logistic';
import BasketIcon from '@/components/icons/BasketIcon';
import { useTranslation } from 'react-i18next';


const BuyAreaPriceArea = ({ grossPrice, netPrice }) => {

  const _hideNetPrice = useSelector((e => e.settings['hideNetPrice']))
  const hideNetPrice = _hideNetPrice == 'true' || _hideNetPrice == true ? true : false
  const _showGrossPrice = useSelector(e => e.settings.ShowGrossPrice);


  const showGrossPrice = (_showGrossPrice == 'true' || _showGrossPrice == true) ? true : false

  const _showBtwPrice = useSelector(e => e.settings.ShowBtwPrice)
  const ShowBtwPrice = (_showBtwPrice == 'true' || _showBtwPrice == true) ? true : false

  const formatedPrice = useCurrencyWithCode();
  const { t } = useTranslation();
  if (showGrossPrice && hideNetPrice) {
    return (<React.Fragment>

      <div className="relative w-fit [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[25px] text-right tracking-[0] leading-[normal]">
        {formatedPrice(grossPrice)}
      </div>

    </React.Fragment>)
  }

  else if (showGrossPrice && !hideNetPrice) {
    return (<React.Fragment>
      <div className="relative self-stretch [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c80] text-[18px] text-right tracking-[0] leading-[normal] line-through">
        {formatedPrice(grossPrice)}
      </div>
      <div className='flex'>
        <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#1C9AE1] text-[13px] tracking-[0] leading-[normal] flex flex-row gap-[5px]">
          {ShowBtwPrice && <p style={{
            color: '#1C9AE1',
            marginTop: '6px'
          }} >{t('in-vat')}</p>}
        </div>
        <div className="ml-3 relative w-fit [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[25px] text-right tracking-[0] leading-[normal]">
          {formatedPrice(netPrice)}
        </div>
      </div>
    </React.Fragment>)
  }
  else {
    return (<React.Fragment>

      <div className='flex'>
        <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#1C9AE1] text-[13px] tracking-[0] leading-[normal] flex flex-row gap-[5px]">
          {ShowBtwPrice && <p style={{
            color: '#1C9AE1',
            marginTop: '6px'
          }} >{t('in-vat')}</p>}
        </div>
        <div className="ml-3 relative w-fit [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[25px] text-right tracking-[0] leading-[normal]">
          {formatedPrice(netPrice)}
        </div>
      </div>

    </React.Fragment>)
  }



}


const BuyAreav2 = ({ deliveries, fastestAvability, otherSellers = [], onClickOtherSellers, deposit = 0, Plate, externalInformation = {} }) => {
  const settings = useSelector(e => e.settings);
  const d = useDispatch();


  const stocks = fastestAvability?.stocks;
  const price = fastestAvability?.price
  // const isExist = fastestAvability?.isExist //Direct mi?
  const rowId = fastestAvability?.rowId
  const otherWarehouseStocks = calculateOtherWarehouseStocks(stocks) // diğer satıcılar
  const isAddedBasketByRowId = useIsAddedBasketByRowId(rowId)
  const qty = isAddedBasketByRowId?.Quantity  //baskete ekli olan qty
  const activeStock = findStock(stocks, (qty || 0)); //şuanki depodan geliş zamanı etc.
  const saleStatus = activeStock && price?.NetPrice;
  const minimumOrderQuantity = fastestAvability?.TotalPiecesInPackage || 1

  const isAdmin = (useSelector((e) => e?.session?.account?.Role) == 1) ? true : false
  const timestamp = activeStock?.logisticTime?.timestamp
  const timestampToRegion = activeStock?.logisticTime?.timestampToRegion

  const formatedPrice = useCurrencyWithCode();

  const isExist = deliveries.includes(activeStock?.WarehouseId) ? true : false

  const { t } = useTranslation()

  const [loadingBasket, setLoadingBasket] = useState(false)
  const addToBasket = () => {
    setLoadingBasket(true)
    try {

      let _ex = {}

      if (externalInformation) _ex = { ...externalInformation }

      return d(insertNewBasket({
        quantity: minimumOrderQuantity,
        RowId: rowId,
        Plate: Plate,
        ...externalInformation,
        cb: (err) => {
          console.log(err)
          setLoadingBasket(false)
        }
      }))

    } catch (e) {
      console.log(e)
    }
    setLoadingBasket(false)
  }



  const updateBasketHandler = (quantity) => {


    if (quantity < (minimumOrderQuantity)) {
      setLoadingBasket(true);
      d(deleteBasketById({
        basketId: isAddedBasketByRowId?.Id,
        cb: () => {
          setLoadingBasket(false)
        }
      }))
    } else {
      setLoadingBasket(true)
      d(updateStoreBasket({
        basketId: isAddedBasketByRowId?.Id,
        Quantity: quantity,
        cb: () => {
          setLoadingBasket(false)
        }
      }))
    }
  }


  const onChangeQty = (type, value) => {
    if (type == 'decrease') {
      updateBasketHandler(parseInt(qty) - parseInt(minimumOrderQuantity))
    } else if (type == 'increment') {
      updateBasketHandler(parseInt(qty) + parseInt(minimumOrderQuantity))
    } else if (type == 'change') {
      if (parseInt(value) % minimumOrderQuantity != 0) {
        return message.error('invalid value')
      }
      updateBasketHandler(parseInt(value))

    }
  }

  return (
    <React.Fragment>

      {/* {saleStatus  ? 'satılır' : 'satılamaz'} */}
      <div className="flex flex-col items-end justify-center relative self-stretch w-full flex-[0_0_auto]">

        <BuyAreaPriceArea netPrice={price?.NetPrice} grossPrice={price?.GrossPrice} />
        {deposit ? formatedPrice(deposit) + '(' + t('deposit') + ')' : null}


      </div>


      <BuyAreav2Logistic warehouseName={activeStock?.WarehouseName} supplierId={fastestAvability?.supplierId} logisticTime={timestamp} logisticTimeToRegion={timestampToRegion} saleStatus={saleStatus} otherWarehouseStocks={otherWarehouseStocks} StockQuantity={parseInt(activeStock?.StockQuantity)} isExist={isExist} />

      <div className="flex items-start gap-[5px] relative rounded-[6px] w-[100%] ">

        {isAddedBasketByRowId ? <CounterButton value={parseInt(qty)} onChange={onChangeQty} /> : <button onClick={addToBasket} disabled={!saleStatus} className=" flex items-center justify-around p-[5px] relative rounded-[6px] w-[100%]" style={{ backgroundColor: settings?.['HeaderBgColor'] }}>
          {saleStatus ? (loadingBasket ? <LoadingOutlined style={{
            color: 'white',
            fontSize: '24px'
          }} /> : <BasketIcon className="relative w-[24px] h-[24px]" />) : <PhoneIcon color={settings['HeaderTextColor']} className="relative w-[24px] h-[24px]" />}
        </button>}


        {otherSellers?.length ? <Badge count={otherSellers?.length}>
          <div>
            <div onClick={onClickOtherSellers} className="inline-flex items-center gap-[5px] p-[5px] relative bg-[#e4e4e4] rounded-[6px] garage-icon">
              <GarageIcon className="relative w-[24px] h-[24px] cursor-pointer" />
            </div>
          </div>

        </Badge> : null}
      </div>

    </React.Fragment>
  )
}

export default BuyAreav2