import { Skeleton } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const LayoutLogin = ({ element, image = true }) => {
  // const [borderRadius, setBorderRadius] = useState(0)
  // const [imgProp, setimgProp] = useState({ borderRadius: '0%' })
  const settings = useSelector(e => e.settings)

  // useEffect(() => {
  //   setimgProp({ borderRadius: borderRadius + '%' })
  // }, [borderRadius])

  useEffect(() => {
    //   const q = new URLSearchParams(location.search);
    //   if (q.get('session') == 'expired')
    //     message.error("Your session has expired. please login again !");
  }, [])

  function getRandomNumber() {
    const min = 1;
    const max = 9;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setRandomNumber(getRandomNumber());
  }, []);

  const [randomNumber, setRandomNumber] = useState();

  return (
    <div className="login-card">
      <div className="card-left">
        <div className="login-logo">
          {image ? <div className="flex justify-center">
            <img style={{ maxHeight: '140px', width: 'auto' }} src={settings['base-logo']} alt="Company Logo" />
          </div> : null}
        </div>
        {element}
      </div>
      <div className="card-right">
        {randomNumber &&
          <Skeleton avatar title={false} loading={false} active>
            <img loading="lazy" style={{ width: 'auto' }} src={`https://material.parts-soft.net/webshop-media/login-image-${randomNumber}.png`} alt={`Login Background ${randomNumber}`} />
          </Skeleton>
        }
      </div>
    </div>


    // <Card className="rotate">
    //   {image ? <div className="flex justify-center">
    //     <img style={{ maxHeight: '140px', width: 'auto' }} src={settings['base-logo']} />
    //   </div> : null}
    //   <div style={{ width: "100%" }}>
    //     <div style={{ alignItems: "center", justifyContent: "center" }}>
    //       <div className="login-container-left">
    //         <div className="pl-20 pr-20">
    //           <div className="login-form mt-10">
    //             <h1 className="text-xl text-center"></h1>
    //           </div>
    //           {element}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Card>
  );


}


export default LayoutLogin;
