import { Button, Drawer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setShowDrawerSearch } from '@/store/site'
import PartsLookup from './PartsLookup'
import TyreSearch from './TyreSearch'
import UniversalPartsSearch from './UniversalPartsSearch'
import VehicleSearch from './VehicleSearch'
import VinSearch from './VinSearch'
import TypeSearch from './TypeSearch'
import EdsVinSearch from './EdsVinSearch'
import getCatalogName from '@/helpers/getCatalogName'

const DrawerSearch = () => {
  const selector = useSelector((e) => e.site)
  const open = selector.showDrawerSearch
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const showDrawer = () => {
    dispatch(setShowDrawerSearch(true))
  }

  const onClose = () => {
    dispatch(setShowDrawerSearch(false))
  }

  const catalogName = getCatalogName()

  return (
    <>
      <Drawer title={t('search')} placement="left" onClose={onClose} open={open} width={415}>
        <PartsLookup onClose={onClose} />
        <TyreSearch onClose={onClose} />
        <UniversalPartsSearch onClose={onClose} />
        <VehicleSearch onClose={onClose} />
        <VinSearch onClose={onClose} />
        {catalogName == 'TECDOC' && <TypeSearch onClose={onClose} />}
        {/* <EdsVinSearch /> */}
      </Drawer>
    </>
  )
}

export default DrawerSearch
