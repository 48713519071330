import getTiresHistoryList from '@/core/tires/getTiresHistoryList'
import { setHistoryList, setLoading } from '@/store/tiresHistory'
import { Avatar, Button, Collapse, List, message, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import deleteTiresHistory from '@/core/tires/deleteTiresHistory'
import useAddTab from '@/hooks/assistant/useAddTab'
import TrashIcon from '@/extra/TrashIcon'
const { Panel } = Collapse;
const TiresHistory = () => {


    const history = useSelector(e => e.tiresHistory);
    const d = useDispatch();
    const addTab = useAddTab();
    const session = useSelector(e => e?.session);

    useEffect(() => {
        d(setLoading(true))
        getTiresHistoryList().then(e => {
            d(setHistoryList(e.data.data))
        }).catch(f => {

        }).finally(() => d(setLoading(false)))
    }, [session?.relationId])

    const destroy = (id) => {
        d(setLoading(true))
        deleteTiresHistory(id).then(e => {
            d(setHistoryList(history.historyList.filter(x => x.Id != id)))
        }).catch(f => {
            message.error("Something went wrong")
        }).finally(() => d(setLoading(false)))
    }

    const add = (e) => {
        addTab({
            title: "Tires" + ' - ' + e,
            key: "tires",
            type: 'Tires',
            tiresCode : e
        })
        
    }


return (
    <div>



        <List
            className="demo-loadmore-list"
            loading={history.loading}
            itemLayout="horizontal"
            dataSource={history.historyList}
            renderItem={(item) => (
                <List.Item
                    actions={[<a key="list-loadmore-more"><TrashIcon onClick={() => destroy(item.Id)} /></a>]}
                >
                    <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                            avatar={<Avatar onClick={()=>add(item.TiresCode)} src={"https://material.parts-soft.net/webshop-media/tires.png"} />}
                            title={<a onClick={()=>add(item.TiresCode)} >{item.TiresCode}</a>}
                            description={moment(item.UpdatedAt).format('DD.MM.YYYY HH:mm')}
                        />
                        <div>{moment(item.UpdatedAt).format('DD.MM.YYYY HH:mm')}</div>
                    </Skeleton>
                </List.Item>
            )}
        />
    </div>
)
}

export default TiresHistory